import "./subpage-header.scss";
import React from "react";

const SubpageHeader = ({ image, title, borderColor }) => {
	return (
		<div
			className="subpage-header"
			style={{
				backgroundImage: `url(${image})`,
				borderColor: borderColor,
			}}
		>
			<h1 className="subpage-header__title">{title}</h1>
		</div>
	);
};

export default SubpageHeader;
