import React from "react";
import { Link } from "gatsby";
import "./breadcrumbs.scss";

const breadcrumbs = ({
	currentLocationLabel,
	prevLocationLabel,
	prevLocationLink,
}) => {
	return (
		<div className="breadcrumbs">
			<div className="container">
				<Link
					to="/"
					className="breadcrumbs__link breadcrumbs__link--home"
				>
					Strona Główna
				</Link>
				<span className="breadcrumbs__arrow"></span>
				{prevLocationLabel && (
					<>
						<Link
							to={prevLocationLink}
							className="breadcrumbs__link"
						>
							{prevLocationLabel}
						</Link>
						<span className="breadcrumbs__arrow"></span>
					</>
				)}
				<span className="breadcrumbs__link breadcrumbs__link--current">
					{currentLocationLabel}
				</span>
			</div>
		</div>
	);
};

export default breadcrumbs;
