import "styles/pages/page-newsletters.scss";
import React, { useEffect } from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumbs from "../components/Breadcrumbs";
import classNames from "classnames";
import { useState } from "react";

const NewsletterPage = ({ pageContext }) => {
	const pageNewsletter = pageContext.pageContent;
	const [activeTab, setActiveTab] = useState(null);

	useEffect(() => {
		setActiveTab(pageNewsletter?.newsletterContent[0].year);
	}, []);

	return (
		<Layout headerColor="#6AD044">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/newsletters-bg.png").default}
			/>
			<Breadcrumbs currentLocationLabel="Newsletters" />
			<section className="newsletters">
				<div className="container">
					<div className="row">
						<h2 className="section-title">Newsletters</h2>
						<div className="newsletters__container">
							<div className="newsletters__tabs">
								{pageNewsletter?.newsletterContent?.map(
									(item, index) => (
										<div
											className={classNames(
												`newsletters__tab`,
												{
													[`newsletters__tab--active`]:
														activeTab ===
														parseInt(item?.year),
												}
											)}
											onClick={() =>
												setActiveTab(
													parseInt(item?.year)
												)
											}
											key={index}
										>
											{item?.year}
										</div>
									)
								)}
							</div>
							<div className="newsletters__content">
								{pageNewsletter?.newsletterContent
									?.filter(
										(item) =>
											parseInt(item?.year) === activeTab
									)
									?.map((item, index) => (
										<div key={index}>
											{item?.files?.map((node, i) => (
												<div
													className="newsletters__item"
													key={i}
												>
													<a
														target="__blank"
														href={
															node?.file
																?.localFile
																?.sourcepublicURLUrl
														}
													>
														<img
															src={
																require("../assets/images/newsletter__icon.png")
																	.default
															}
															alt=""
															className="img-fluid newsletters__icon"
														/>
													</a>
													<a
														target="__blank"
														href={
															node?.file
																?.localFile
																?.publicURL
														}
													>
														{node.name}
													</a>
												</div>
											))}
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default NewsletterPage;
